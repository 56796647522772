<template>
  <div>
  <iq-card>
    <template v-slot:body>
      <div class="user-post-data p-3">
          <div class="d-flex flex-wrap">
            <div class="media-support-info mt-2">
              <b-form class="comment-text align-items-center mt-3" action="javascript:void(0);">
              <b-form-select plain v-model="editCategory" :options="userState.role === 'admin' ? adminOptions : options" />
                <div :style="{padding: '10px'}" />
              <b-form-input type="text" v-model="editTitle" class="rounded" />
            </b-form>
            <div class="text-right">
              <h6>
                <p class="mb-0">{{ data.name }}</p>
              </h6>
              <p class="mb-0 text-secondary">{{ data.date }}</p>
            </div>

          </div>
        </div>
      </div>
    </template>
    <hr class="m-0" />
    <div class="user-post">
      <div class="output ql-snow">
        <SmartEditor
        :content="data.content"
        :title="editTitle"
        :category="data.category"
        :comment_allow="data.comment_allow"
        :highlight="data.highlight"
         />
      </div>
    </div>
  </iq-card>
</div>
</template>
<script>
import { core } from '../../config/pluginInit'
import SmartEditor from './Form/SmartEditor.vue'
import posted from '../../services/dashboard/posted'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardEdit',
  components: {
    SmartEditor
  },
  mounted () {
    core.index()
    this.setCategory()
    this.refresh()
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      categoryState: 'Category/categoryState'
    })
  },
  data () {
    return {
      editTitle: '',
      editCategory: '',
      editHighlight: false,
      editComment: false,
      options: [
        { html: '<b>카테고리를 선택해주세요.</b>', value: '', disabled: true }
      ],
      adminOptions: [
        { html: '<b>카테고리를 선택해주세요.</b>', value: '', disabled: true },
        { text: '공지사항', value: '공지사항' }
      ]
    }
  },
  methods: {
    setCategory () {
      for (let i = 0; i < this.categoryState.length; i++) {
        const category = {
          html: `<b>---- ${this.categoryState[i].category_name} ----</b>`,
          value: '',
          disabled: true
        }
        this.options.push(category)
        this.adminOptions.push(category)
        if (this.categoryState[i].subcategory.length !== 0) {
          for (let j = 0; j < this.categoryState[i].subcategory.length; j++) {
            const item = {
              text: this.categoryState[i].subcategory[j].category_name,
              value: this.categoryState[i].subcategory[j].category_name
            }
            this.options.push(item)
            this.adminOptions.push(item)
          }
        }
      }
    },
    refresh () {
      if (this.data === undefined) {
        const pk = this.$route.params.pk
        posted.detail(pk).then(res => {
          this.data = res.data.community
          this.refresh()
        })
      } else {
        this.editTitle = this.data.title
        this.editCategory = this.data.category
        this.editComment = this.data.comment_allow
        this.editHighlight = this.data.highlight
      }
    }
  },
  props: ['data']
}
</script>
<style scoped>
.progress-bar-info {
  background-color: #0cd2e3 !important;
}

.progress-bar-success {
  background-color: #1be1b3 !important;
}

.progress-bar-warn {
  background-color: #f4b72a !important;
}

.progress-bar-pink {
  background-color: #fd6c9e !important;
}
</style>
